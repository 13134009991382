import React from 'react'
import get from 'lodash.get'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { getYouTubeId, getPreviewUrl, getEmbedUrl } from 'helpers/youtube'
import StylistTools from 'blocks/StylistTools'
import Videos from 'blocks/Videos'
import url from 'helpers/url'
import { useIntl, Link } from 'gatsby-plugin-react-intl'
import HeroSlider from 'components/HeroSlider'

import {
    Spacing,
    SharedStyles,
    Container,
    ImageTextGrid,
    VideoBlock,
    StyleBlock,
} from '../kenra-storybook/index'

let Color = require('color')

const { StSectionInfo, StTitle, StLinkMore } = SharedStyles

export default function ColorLanding({ page }) {
    let intl = useIntl()
    const video1id = getYouTubeId(page.video1Url)
    const video2id = getYouTubeId(page.video2Url)
    const videoItems = []
    if (video1id) {
        videoItems.push({
            title: page.video1Title,
            text: page.video1Text,
            btnSizeMedium: true,
            bgImg: getPreviewUrl(video1id),
            videoType: 'youtube',
            autoPlay: true,
            fitWidth: true,
            allow:
                'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
            videoOptions: [
                {
                    src: getEmbedUrl(video1id),
                },
            ],
        })
    }
    if (video2id) {
        videoItems.push({
            title: page.video2Title,
            text: page.video2Text,
            btnSizeMedium: true,
            bgImg: getPreviewUrl(video2id),
            videoType: 'youtube',
            autoPlay: true,
            fitWidth: true,
            allow:
                'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
            videoOptions: [
                {
                    src: getEmbedUrl(video2id),
                },
            ],
        })
    }

    const headerVideo = get(page, 'video.localFile.publicURL')



    return (
        <>
            {headerVideo ? <Spacing removeSpaceTop>
                <VideoBlock
                    fitWidth
                    renderVideo
                    imgUrl={get(page, 'videoPreview.localFile.publicURL')}
                >
                    <video preload="metadata">
                        <source
                            src={get(page, 'video.localFile.publicURL')}
                            type="video/mp4"
                        />
                        {intl.formatMessage({
                            id: 'kenra-video-not-supported',
                        })}
                    </video>
                </VideoBlock>
            </Spacing> : <Spacing removeSpaceTop>

                <HeroSlider slides={page.slides} />

            </Spacing>}

            <Spacing>
                <StSectionInfo>
                    <Container>
                        <StTitle>
                            <h2>{page.title}</h2>
                        </StTitle>
                        {documentToReactComponents(JSON.parse(page.text.raw || '{}'))}
                        <StLinkMore styleBig>
                            <Link to={url.colorList}>
                                {intl.formatMessage({ id: 'product-view-all' })}
                            </Link>
                        </StLinkMore>
                    </Container>
                </StSectionInfo>
            </Spacing>

            {videoItems.length > 0 && <Spacing removeSpaceTop>
                <StyleBlock
                    linkMobileText={intl.formatMessage({
                        id: 'kenra-mobile-watch-more',
                    })}
                    linkMobileMore="/video"
                    noOverlap={true}
                    items={videoItems}
                />

            </Spacing>}

            {page.bottomImages && (
                <Spacing removeSpaceTop>
                    <ImageTextGrid
                        styleSquare
                        rowRevers
                        items={page.bottomImages.map((image, index) => {
                            let bgColor = get(
                                page,
                                `bottomColors[${index}]`,
                                '#d3d3d3'
                            )
                            return {
                                rowBg: bgColor,
                                img: get(image, 'localFile.publicURL'),
                                title: get(page, `bottomTitles[${index}]`),
                                text: get(page, `bottomTexts[${index}]`),
                                linkText: get(
                                    page,
                                    `bottomButtonTexts[${index}]`
                                ),
                                linkHref: get(
                                    page,
                                    `bottomButtonLinks[${index}]`
                                ),
                                linkStyleWhite: Color(bgColor).isDark(),
                                rowColorWhite: Color(bgColor).isDark(),
                            }
                        })}
                    />
                </Spacing>
            )}

            <Spacing>
                <Videos
                    title={intl.formatMessage({ id: 'kenra-education-videos' })}
                    videos={page.videos}
                    bgImage={get(page, 'videosImage.localFile.publicURL')}
                />
            </Spacing>

            {page.stylistTools && (
                <Spacing>
                    <StylistTools tools={page.stylistTools} />
                </Spacing>
            )}
        </>
    )
}
