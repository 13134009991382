import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import ColorLanding from 'components/ColorLanding';
import SEO from 'components/seo';
import get from 'lodash.get';

export default function KenraColorLandingPage(props) {
    let {
        data: { contentfulColorLanding },
    } = props;

    return (
        <Layout>
            <SEO
                description={get(
                    contentfulColorLanding,
                    'seoListing.description.description'
                )}
                title={get(contentfulColorLanding, 'seoListing.seoTitle')}
                slug={'kenra-color'}
            />
            <ColorLanding page={contentfulColorLanding} />
        </Layout>
    );
}

export const query = graphql`
    query($locale: String) {
        contentfulColorLanding(node_locale: { eq: $locale }) {
            title
            text {
                raw
            }
            bottomTitles
            bottomTexts
            bottomImages {
                localFile {
                    publicURL
                }
            }
            bottomColors
            bottomButtonTexts
            bottomButtonLinks
            slides {
                ...SlideFields
            }
            videosImage {
                localFile {
                    publicURL
                }
            }
            videos {
                ...ContentfulVideoFields
            }
            stylistTools {
                ...ContentfulToolFields
            }
            node_locale
            seoListing {
                ...ContentfulSeoListingFields
            }
            video2Url
            video2Title
            video2Text
            video1Url
            video1Title
            video1Text
            video {
                localFile {
                    publicURL
                }
            }
            videoPreview {
                localFile {
                    publicURL
                }
            }
        }
    }
`;
